
export function formatCurrency(value:number, currency:string = 'USD'): string{
    return new Intl.NumberFormat(navigator.language, { style: 'currency', currency:  currency, currencyDisplay: 'code', maximumFractionDigits:2, minimumFractionDigits:0}).format(value);
}
  
export function formatDate(date:string| Date): string{
    date = new Date(date);
    return date.toLocaleDateString(navigator.language, {day: '2-digit', month: '2-digit', year: 'numeric'});
}

export function formatNumber(value:number, options = {}): string {
    return new Intl.NumberFormat(navigator.language, options).format(value);
}