import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "@yukawa/chain-base-angular-client";
import { QueryResult } from "@yukawa/chain-base-angular-domain";
import { Company } from "app/types/domain/profile";
import { BehaviorSubject, map } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    companies$: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>([]);

    constructor(
        private _httpClient: HttpClient,
        private _configService: ConfigService
        ){}

    get companies(){
        return this.companies$.asObservable();
    }

    loadCompanies(){
        return this._httpClient.post<QueryResult<Company>>(this._configService.formatUrl('companyUrl') + '/query',{})
        .subscribe((comps: QueryResult<Company>) => {
            this.companies$.next(comps.items);
        });
    }

}